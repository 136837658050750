<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-nav current="1"></service-support-nav>
        <div>
          <img src="../assets/images/service-support/technical-state-banner.png" alt="" class="img">
        </div>
        <div class="container">
            <div class="questions-title">常见问题</div>
            <div class="pb40">
                <el-collapse v-model="activeNames" class="questions-item">
      <el-collapse-item  v-for="(item,index) in question" :key="index" :title="item.problem" :name="index">
        <div style="color:#999 ;">
            {{ item.answer }}
        </div>
      </el-collapse-item>
    </el-collapse>
            </div>
        </div>
  
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import ServiceSupportNav from '@/components/ServiceSupportNav'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      ServiceSupportNav
    },
  
    setup() { 
        const router = useRouter()
        const activeNames = ref([0])
      const useData = reactive({
          question: [
              {
                problem: "1、支持哪些设备接入？",
                answer:"我们有专业的研发、技术团队设备大多都可以做接入，具体要看你的接口是什么类型的。如精密空调、UPS、智能配电柜、 蓄电池、温湿度、漏水、烟感、红外、防雷、消防、视频、门禁等。",
              },
              {
                problem: "2、能不能把动环数据接入到采集我们企业自己的平台？",
                answer:"可以接入到第三方平台，我们这边可以提供接入接口文档。",
              },
              {
                problem: "3、你们的主机能接入视频吗，想了解视频接入的相关数据？",
                answer:"我们可以接入的，你们提供设备的通讯协议就可以了，接入云台同时也可以控制摄像机的转动。海康、大华等其他牌子都能 接入。",
              },{
                problem: "4、安装你们的系统设备后，访问管理平台软件，我们自己的电脑还需要配置软件吗？",
                answer:"不需要，我们的主机自带有软件，登陆浏览器就可以访问后台。",
              },{
                problem: "5、系统软件支不支持远程访问监控？",
                answer:"主机是软硬件一体化，B/S架构，支持WEB服务，可以登录浏览器远程访问后台进行实时数据查看、操作。",
              },
              {
                problem: "6、你们设备都是用什么连接的？",
                answer:"设备都是用超五、六类网线就可以连接，大多设备都能做到即插即用，很方便。",
              }
        ]
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
   
    return {
        ...toRefs(useData),
      goTo,
      go,
      activeNames
    }
  }
}
  </script>
  <style scoped>
.questions-title{
  font-size: 30px;text-align:center ;padding: 60px 0px;
}

.pb40{
  padding-bottom: 40px;
}
</style>
<style>
.el-collapse-item__header,.el-collapse-item__content{
  font-size: 18px;
}
</style>
  